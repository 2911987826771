.pt5 {padding-top: 5px;}
.pt10 {padding-top: 10px;}
.pt20 {padding-top: 20px;}
.pt30 {padding-top: 30px;}
.pt40 {padding-top: 40px;}
.pt50 {padding-top: 50px;}
.pt60 {padding-top: 60px;}
.pt70 {padding-top: 70px;}
.pt80 {padding-top: 80px;}
.pt90 {padding-top: 90px;}
.pt100 {padding-top: 100px;}

.pr5 {padding-right: 5px;}
.pr10 {padding-right: 10px;}
.pr20 {padding-right: 20px;}
.pr30 {padding-right: 30px;}
.pr40 {padding-right: 40px;}
.pr50 {padding-right: 50px;}
.pr60 {padding-right: 60px;}
.pr70 {padding-right: 70px;}
.pr80 {padding-right: 80px;}
.pr90 {padding-right: 90px;}
.pr100 {padding-right: 100px;}

.pl5 {padding-left: 5px;}
.pl10 {padding-left: 10px;}
.pl20 {padding-left: 20px;}
.pl30 {padding-left: 30px;}
.pl40 {padding-left: 40px;}
.pl50 {padding-left: 50px;}
.pl60 {padding-left: 60px;}
.pl70 {padding-left: 70px;}
.pl80 {padding-left: 80px;}
.pl90 {padding-left: 90px;}
.pl100 {padding-left: 100px;}

.pb5 {padding-bottom: 5px;}
.pb10 {padding-bottom: 10px;}
.pb20 {padding-bottom: 20px;}
.pb30 {padding-bottom: 30px;}
.pb40 {padding-bottom: 40px;}
.pb50 {padding-bottom: 50px;}
.pb60 {padding-bottom: 60px;}
.pb70 {padding-bottom: 70px;}
.pb80 {padding-bottom: 80px;}
.pb90 {padding-bottom: 90px;}
.pb100 {padding-bottom: 100px;}

.p0 {padding: 0px;}
.p5 {padding: 5px;}
.p10 {padding: 10px;}
.p20 {padding: 20px;}
.p30 {padding: 30px;}
.p40 {padding: 40px;}
.p50 {padding: 50px;}
.p60 {padding: 60px;}
.p70 {padding: 70px;}
.p80 {padding: 80px;}
.p90 {padding: 90px;}
.p100 {padding: 100px;}

.mt5 {margin-top: 5px;}
.mt10 {margin-top: 10px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mt40 {margin-top: 40px;}
.mt50 {margin-top: 50px;}
.mt60 {margin-top: 60px;}
.mt70 {margin-top: 70px;}
.mt80 {margin-top: 80px;}
.mt90 {margin-top: 90px;}
.mt100 {margin-top: 100px;}

.mr5 {margin-right: 5px;}
.mr10 {margin-right: 10px;}
.mr20 {margin-right: 20px;}
.mr30 {margin-right: 30px;}
.mr40 {margin-right: 40px;}
.mr50 {margin-right: 50px;}
.mr60 {margin-right: 60px;}
.mr70 {margin-right: 70px;}
.mr80 {margin-right: 80px;}
.mr90 {margin-right: 90px;}
.mr100 {margin-right: 100px;}

.ml5 {margin-left: 5px;}
.ml10 {margin-left: 10px;}
.ml20 {margin-left: 20px;}
.ml30 {margin-left: 30px;}
.ml40 {margin-left: 40px;}
.ml50 {margin-left: 50px;}
.ml60 {margin-left: 60px;}
.ml70 {margin-left: 70px;}
.ml80 {margin-left: 80px;}
.ml90 {margin-left: 90px;}
.ml100 {margin-left: 100px;}

.mb5 {margin-bottom: 5px;}
.mb10 {margin-bottom: 10px;}
.mb20 {margin-bottom: 20px;}
.mb30 {margin-bottom: 30px;}
.mb40 {margin-bottom: 40px;}
.mb50 {margin-bottom: 50px;}
.mb60 {margin-bottom: 60px;}
.mb70 {margin-bottom: 70px;}
.mb80 {margin-bottom: 80px;}
.mb90 {margin-bottom: 90px;}
.mb100 {margin-bottom: 100px;}

.m0 {margin: 0px;}
.m5 {margin: 5px;}
.m10 {margin: 10px;}
.m20 {margin: 20px;}
.m30 {margin: 30px;}
.m40 {margin: 40px;}
.m50 {margin: 50px;}
.m60 {margin: 60px;}
.m70 {margin: 70px;}
.m80 {margin: 80px;}
.m90 {margin: 90px;}
.m100 {margin: 100px;}

.w25{width:25%}
.w50{width:50%}
.w75{width:75%}
.w100{width:100%}

.text-white {color: white;}
.text-reg {font-weight: 300;}
.text-center {text-align: center;}
.red {color: #CC0000}
.green {color: #66A329}
.background-white {background-color: white;}
.background-lightgrey {background-color: #f7f8fa;}
.border-top-gold {border-top: 2px solid #FFC720;}
.border-top-grey {border-top: 1px solid #D5DFE7;}
.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
.bold {font-weight: 700 !important;}
.italic {font-style: italic !important;}
.pointer {cursor: pointer;}
.overflow-scroll {overflow: scroll;}
.display-none {display: none;}
@media all and (max-width: 768px) {
    .flex-column-mobile {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .mobile-hide {
        display: none;
    }
    .mobile-show {
        display: block;
    }
}
@media all and (min-width: 768px) {
    .mobile-hide {
        display: block;
    }
    .mobile-show {
        display: none;
    }
}
