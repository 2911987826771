$mid-grey: #515151;
$smoke: #F6F8FA;
$secondary: #FFC720;
$primary: #5E2698;

@font-face {
    font-family: 'Gotham-Medium';
    src: local('Gotham-Medium'), url(./fonts/Gotham/woff/GothamSSm-Medium_Web.woff) format('truetype');
}
@font-face {
    font-family: 'Gotham-Book';
    src: local('Gotham-Book'), url(./fonts/Gotham/woff/GothamSSm-Book_Web.woff) format('truetype');
}
@font-face {
    font-family: 'Gotham-Bold';
    src: local('Gotham-Bold'), url(./fonts/Gotham/woff/GothamSSm-Bold_Web.woff) format('truetype');
}

body {
    font-family: Verdana, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #515151;
    margin: 0;
}
html, body, #root {
    height: 100%;
    font-size: 0.956em;
    line-height: 1.5em;
}
a {
    color: #4F2683;
}
a:not([href]) {
    color: #4F2683 !important;
}
a:hover {
    color: #a208d0;
    text-decoration: none;
}
a:not([href]):hover {
    color: #a208d0 !important;
    text-decoration: none;
}
.MuiButtonBase-root {
    font-style: normal !important;
}
a.MuiButtonBase-root.MuiButton-containedPrimary:hover {
    color: white;
}
button:focus {
    outline: none;
}
p.small {
    font-size: 0.751em;
}
.error{
    color:#FF9494;
}
.error-box{
    text-align: center;
}
button.simple-text-button {
    background-color: transparent;
    text-transform: none;
    padding: 0 5px;
    margin: 0 3px;
}
.button-icon {
    margin-right: 7px;
}
a.textLink {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}
a.textLink-yellow {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: orange;
}
em.text-yellow {
    text-decoration: none;
    font-weight: 600;
    display: flex;
    color: orange;
}
.root-container {
    //background-color: #8126a7;
    // background-image: url('img/background-map.jpg');
    //background-size: cover;
    margin: 0;
    padding: 0;
}
.support-hero {
    color: white;
    background-image: url('img/PPD_Patient_bg-min.jpg');
    background-color: #3f1c68;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100%;
    &.clinical {
        background-image: url('img/PP_ClientPartner_bg-min.jpg');
        @media all and (max-width: 768px) {
            background-image: url('img/PPD_SolidPurple_bg-min.jpg');
        }
    }
    .logo {
        width: 175px;
    }
    h1 {
        font-family: Gotham-Book;
        font-size: 3.41em;
        text-transform: uppercase;
        font-weight: normal;
        line-height: 100%;
        &.green {
            color:#66A329;
        }
    }
    p {
        font-family: Gotham-Book;
        font-size: 1.365em;
        line-height: 1.5em;
    }
    hr {opacity: .25;}
    @media all and (max-width: 768px) {
        background-image: url('img/PPD_SolidPurple_bg-min.jpg');
    }
}
.support-tabs {
    background-color: white;
    margin-top: -50px;
    margin-bottom: 25px;
    .tab {
        svg {opacity: 0;}
        &:hover {
            background-color: #4e26830e;
            cursor: pointer;
        }
        .tab-link {
            display: block;
            text-decoration: none;
            color: black;
        }
    }
    img { width: 100px;}
    h3 {
        text-transform: uppercase;
        font-size: 150%;
        font-weight: 200;
        margin-bottom: 10px;
    }
    p {
        margin-top: 5px;
    }
    .active {
        background-color: #4e26830e;
        svg {
            opacity: 1;
            color: #82C340;
        }
    }
}
.alert {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.819em;
    background: rgb(174,49,28);
    background: linear-gradient(0deg, rgba(174,49,28,1) 0%, rgba(218,69,44,1) 100%);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.329);
    align-items: center;
    padding-right: 10px;
    border-radius: 2px;
    margin-bottom: 10px;
    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        @media all and (max-width: 768px) {
            flex-direction: column;
        }
        font-size: 1.092em;
        cursor: pointer;
    }
    .link {
        @media all and (max-width: 768px) {
            display: none;
        }
        a {
            color: white !important;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.956em;
            cursor: pointer;
        }
    }
    div {
        padding: 10px;
        @media all and (max-width: 768px) {
            padding: 5px;
        }
    }
}
.section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 0;
    margin: 50px 0 25px 0;
    border-bottom: 1px solid #CABDDA;
    .section-back {
        display: none;
    }
    @media all and (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        .section-back {
            display: block;
        }
    }
    .label {
        font-family: Gotham-Book;
        padding: 0;
        margin: 0;
        font-size: 2.048em;
        font-weight: 200;
        font-weight: normal;
        text-transform: uppercase;
        @media all and (max-width: 900px) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    .hours {
        color: #231F20;
        font-weight: bold;
        font-size: 0.956em;
        text-transform: none;
    }
}
.aboutPPD {
    margin-top: 10px;
    .logo {
        width: 175px;
    }
    p {
        line-height: 180%;
    }
    div.divider {
        width: 25%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.25);
        margin: 30px 0;
    }
    a.textLink {
        margin-top: 0px;
    }
    padding: 50px 25px;
    
    background-color: #F7F9FA;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    @media all and (min-width: 960px) {
        background-image: url('img/PPD_WebsiteRedirect_bg-min.jpg');
    }
}
.phoneContact {
    .header {
        background: #3A1A62;
        background: linear-gradient(0deg, #4F2683 0%, #3A1A62 100%);
        padding: 20px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }
    background: #4e268310;
    .body {
        padding: 20px;
        ul {
            margin: 0;
            padding: 0;
            li {
                margin: 0;
                list-style: none;
                padding: 0;
                margin-bottom: 20px;
                span {
                    font-weight: bold;
                    color: #3A1A62;
                    a {
                        text-decoration-line: none;
                    }
                }
            }
        }
    }
}
.contact-info {
    display: flex;
    flex-direction: column;
    a {
        text-decoration-line: none;
    }
    div {
        margin-bottom: 10px;
        span {
            font-weight: bold;
            color: #3A1A62; 
        }
    }
}
.search {
    display: flex;
    align-items: center;
    background-color: #F6F8FA;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #DDE6EC;
    .icon {
        margin-right: 10px;
        width: 1.5em;
        height: 1.5em;
        color: #4F2683;
    }
}

.app-header-admin {
    background: #3A1A62;
    background: linear-gradient(0deg, #4F2683 0%, #3A1A62 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-bottom: 2px solid #FFC720;
}
.adminBody {
    display: flex;
    flex-direction: row;
    background-color: #F6F8FA;
    min-height: calc(88vh - 34px);
    .adminContent {
        padding: 3%;
        width: 100%;
        height: 100%;
        .page-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 20px;
            h1 {
                font-family: Gotham-Book;
                margin: 0;
            }
            .page-header-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
    .main-menu-links {
        padding: 0;
        li {
            list-style: none;
            margin-bottom: 20px;
            a {
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
}
.table-header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.admin-table {
    .table-title {
        color: #4F2683;
        font-weight: 600;
    }
    &:hover {
        cursor: pointer;
    }
}
.admin-menu {
    background-color: white;
    box-shadow: rgb(141, 141, 141) 2px 0 2px;
    a {
        text-decoration: none;
        .main-menu {
            font-family: Gotham-Book;
            font-size: 1.365em;
        } 
    }
    .nav-list {
        .secondary-item {
            color: #3f1c68;
            align-items: flex-start;
            padding: 14px;
            .MuiListItemText-root {
                margin: 0;
                .MuiTypography-root {
                    font-size: 0.819em !important;
                }
            }
        }
        .MuiListItem-gutters {
            padding-left: 20px;
            padding-right: 20px;
        }
        .MuiListItem-button.Mui-selected,
        .MuiListItem-button.active {
            background-color: #EDE9F2;
            border-left: 3px solid #4F2683;
        }
    }  
}
.menu-popover {
    padding: 20px;
    h3, p {
        margin: 5px 0;
        font-weight: normal;
    }
    button {
        margin-top: 10px;
    }
}
.modal-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 4px;
    padding: 25px;
    &.medium {
        width: 60%;
        #modal-modal-title {
            font-family: Gotham-Book;
            font-size: 1.91em;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 100%;
        }
        #modal-modal-title-not-upper {
            font-family: Gotham-Book;
            font-size: 1.91em;
            font-weight: 700;
            line-height: 100%;
        }
    }
    &.large {
        width: 80%;
    }
}
.form-section {
    .form-section-header {
        background-color: #688A9F;
        color: white;
        padding: 20px;
        font-size: 1.092em;
    }
    .form-section-body {
        background-color: #E6ECF0;
        padding: 20px;
    }
    &.green {
        .form-section-header {
            background-color: #67A42A;
        }
        .form-section-body {
            background-color: #E6F3D9; 
        }
    }
}
.account-menu {
    background-color: white;
    border-radius: 4px;
}
.label-formcomponent {
    margin: 0;
    line-height: 1.25;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 5px;
}
.exportexcel-button {
    margin: 3px;
    margin-right: 6px;
    justify-content: center;
}
.programregioncountry-flag {
    width: 25px;
    max-height: 21px;
    margin-right: 5px;
}
/* 

Most of the Material UI Overrides are in App.tsx
If you want to make a global change to an element (e.g., make ALL buttons have square corners, then do it in App.tsx
Else if you want to make a one-off change (e.g., only the button inside the footer needs square corners) then make it here.

There is also a file called Helpers.scss which contains reusable classes (e.g., '.pb30 {padding-bottom: 30px}')

/*

/*Mui Overrides*/
//.MuiContainer-maxWidthLg {
//    max-width: 90% !important;
//}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 50px !important;
}
.MuiTextField-root {
    margin: 10px 0;

    .textfield {
        margin: 0;
    }

    &.textfield {
        margin: 2px 0;

        label {
            background-color: rgba(255, 255, 255, 0);
        }
    }
}
.MuiFormControl-root {
    &.select {
        margin: 2px 0;

        label {
            background-color: white;
            padding-top: 5px;
            padding-bottom: 2px;
            padding-left: 8px;
            padding-right: 5px;
            line-height: 1.25;
        }
    }
}
.MuiAutocomplete-inputRoot {
    padding-top: 1px;
    padding-bottom: 1px;
}
.MuiFormControl-root.MuiTextField-root {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
}
.MuiFormLabel-root .MuiSvgIcon-root.label-info {
    font-size: 0.9rem;
    margin: 0 5px;
    position: relative;
    z-index: 10000;
}
.MuiButtonBase-root.m0{
    margin: 0;
}

/*Tables*/
.MuiTab-root.Mui-selected.MuiButtonBase-root {
    background-color: #f7f8fa;
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
}
.table-filter.MuiFormControl-root {
    display: flex;
    flex-direction: row;
    @media all and (max-width: 900px) {
        flex-direction: column;
    }
    .horizontal-label {
        font-size: 0.819em;
        margin: 10px 5px;
        font-weight: 700;
    }

    .MuiInputBase-root {
        font-size: 0.819em;
        background-color: transparent;
    }

    .MuiSelect-selectMenu.MuiSelect-selectMenu {
        padding-right: 33px;
    }
}

/* Header with logos */
.branded-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    align-items: center;
    .header-left {
        display: flex;
        align-items: center;
        padding: 15px;
        text-transform: uppercase;
        img {
            width: 175px;
            //height: 39px;
            //margin-left: -10px;
        }
        h2 {
            font-family: Gotham-Book;
            font-size: 1.365em;
            color: white;
            font-weight: 600;
            margin: 0;
            padding: 0;
            margin-left: 30px;
            margin-top: 5px;
        }
        // @media all and (max-width: 900px) {
        //     flex-direction: column;
        //     align-items: flex-start;
        //     img {
        //         width: 315px;
        //         height: 32px;
        //         margin-left: 0px;
        //     }
        //     h2 {
        //         margin-left: 0px; 
        //     }
        // }
    }
    .header-right {}
    
    .user-menu {
        display: flex;
        justify-content: space-between;
        padding: 5px 15px;
        align-items: center;
        .customer-logo {
            width: 100px;
            margin: 0 10px;
        }
        // @media all and (max-width: 900px) {
        //     justify-content: flex-end;
        //     .customer-logo {
        //         width: 100px;
        //         margin: 0 auto 0 10px;
        //     }
        // }
    }
    @media all and (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;
    }
}
.user-popover {
    .MuiPaper-root {
        padding: 20px;
    }
}

/* Uppermost header */
.utility-header {
    .MuiContainer-root {
        display: flex;
        justify-content: flex-end;
        min-height:34px;
        .notifications-button {
            padding: 5px;
        }
        @media all and (max-width: 900px) {
            flex-direction: column;
            padding: 0;
            align-items: center;
        }
    }
    // background-image: url(img/footer-bg.jpg);
    background-size: cover;
    position: relative;
    width: 100%;
    top: 0;
    .header-links {
        font-size: 0.887em;
        color: white;
        margin-right: auto;
        width:100%;
        div {
            margin: 0 5px;

            a {
                color: white;
            }
        }
        @media all and (max-width: 900px) {
            margin: 5px;
            display: none;
        }
    }

    .utility-right {
        display: flex;
        background-color: rgba(255,255,255,0.1);
        button.program-button {
            color: white;
            text-transform: capitalize;
            font-size: 0.819em;
            font-weight: 600;
            margin: 0;
            border-radius: 0;
            padding-left: 20px;
            padding-right: 20px;
            min-width: 300px;
            @media all and (max-width: 768px) {
                max-width: 75%;
                line-height: normal;
                text-align: left;
            }
        }
        @media all and (max-width: 768px) {
            width: 100%;
            justify-content: space-evenly;
        }
    }
}

/*Footer*/
.branded-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    font-size: 0.819em;
    background-color: #231F20;
    color: white;
    position: relative;
    //width: 100%;
    @media all and (max-width: 900px) {
        flex-direction: column;
        text-align: center;
    }
    .footer-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        div {
            margin: 0 0 0 10px;
            a {
                color: white;
                text-decoration: none;
            }
        }
        @media all and (max-width: 900px) {
            margin-top: 20px;
            div {
                margin: 0;
            }
        }
    }
}
.footer-legal {
    &.mobile-show {
        @media all and (max-width: 768px) {
            display: flex !important;
        }
    }
    justify-content: space-evenly;
    font-size: 0.683em;
    color: white;
    padding: 10px;
    background-color: #4c5159;
    text-align: center;
    line-height: normal;
    a {
        color: white !important;
        text-decoration: underline;
    }
}
.row-radio-buttons-group {
    font-size: medium;
    accent-color: #5d2498; //primary purple
    margin-top: 10px;
}
.alerts-image {
    max-width: 850px;
    @media all and (max-width: 768px) {
        max-width: 100%;
    }
}